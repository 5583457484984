<template>
  <div>
    <b-card>
      <b-row class="">
        <b-col class="col-md-12">
          <h2
            class="text-blue font-weight-bold col-md-10 col-lg-10 col-xl-11 mt-1"
          >
            {{ $t("email_testing") }}
          </h2>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row class="col-12">
        <h3
          class="text-blue font-weight-bold col-md-10 col-lg-10 col-xl-11 mb-1"
        >
          {{ $t("send_email") }}
        </h3>
      </b-row>
      <validation-observer ref="emailTesting" name="emailTesting">
        <b-form>
          <b-row class="col-12">
            <b-col md="4">
              <b-form-group
                id="email_type"
                :label="$t('email_type') + ':'"
                label-for="email_type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email_type"
                  rules="required"
                >
                  <b-form-select
                    id="email_type"
                    v-model="email_type"
                    :options="emailTypes"
                    :state="errors.length > 0 ? false : null"
                    required
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="email_to"
                :label="$t('email_to') + ':'"
                label-for="email_to"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email_to"
                  rules="required|email"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon" class="cursor-pointer" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="email_to"
                      v-model="email_to"
                      required
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
                <b-form-group
                  id="language'"
                  :label="$t('language') + ':'"
                  label-for="language'"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-select
                      :options="[
                        { text: $t('german'), value: 'de' },
                        { text: $t('english'), value: 'en' },
                      ]"
                      id="language"
                      v-model="language"
                      required
                    ></b-form-select>
                  </b-input-group>
                </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="" class="mt-1 pt-1">
                <b-button @click="sendEmail()" variant="primary" size="sm">{{
                  $t("send_email")
                }}</b-button>
              </b-form-group>
            </b-col>
            
          </b-row>
          <b-row v-if="email_type == 'send-referee-email'" class="col-12">
            <!-- <b-col md="4">
              <b-form-group
                id="referrer_company"
                :label="$t('referrer_company') + ':'"
                label-for="referrer_company"
              >
                <validation-provider
                  #default="{ errors }"
                  name="referrer_company"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="referrer_company"
                      v-model="send_referee_email.referrer_company"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <b-col md="4">
              <b-form-group
                id="referred_company"
                :label="$t('referred_company_id') + ':'"
                label-for="referred_company"
              >
                <validation-provider
                  #default="{ errors }"
                  name="referred_company"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="referred_company"
                      v-model="send_referee_email.referred_company"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="referral"
                :label="$t('referral_code') + ':'"
                label-for="referral"
              >
                <validation-provider #default="{ errors }" name="referral">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="referral"
                      v-model="send_referee_email.referral"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else-if="email_type == 'send-referral-email'" class="col-12">
            <b-col md="4">
              <b-form-group
                id="referrer_company"
                :label="$t('referrer_company_id') + ':'"
                label-for="referrer_company"
              >
                <validation-provider
                  #default="{ errors }"
                  name="referrer_company"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="referrer_company"
                      v-model="send_referral_email.referrer_company"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="company"
                :label="$t('company_id') + ':'"
                label-for="company"
              >
                <validation-provider #default="{ errors }" name="company">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="company"
                      v-model="send_referral_email.company"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else-if="email_type == 'withdraw-approved'" class="col-12">
            <b-col md="4">
              <b-form-group
                id="transaction"
                :label="$t('transaction_id') + ':'"
                label-for="transaction"
              >
                <validation-provider #default="{ errors }" name="transaction">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="transaction"
                      v-model="withdraw_approved.transaction"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col md="4">
              <b-form-group
                id="company"
                :label="$t('company_id') + ':'"
                label-for="company"
              >
                <validation-provider #default="{ errors }" name="company">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="company"
                      v-model="withdraw_approved.company"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
          </b-row>
          <b-row v-if="email_type == 'deposit-approved'" class="col-12">
            <!-- <b-col md="4">
              <b-form-group
                id="company"
                :label="$t('company_id') + ':'"
                label-for="company"
              >
                <validation-provider #default="{ errors }" name="company">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="company"
                      v-model="deposit_approved.company"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4"> -->
              <b-form-group
                id="transaction"
                :label="$t('transaction_id') + ':'"
                label-for="transaction"
              >
                <validation-provider #default="{ errors }" name="transaction">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="transaction"
                      v-model="deposit_approved.transaction"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col md="4">
              <b-form-group
                id="settings"
                :label="$t('settings') + ':'"
                label-for="settings"
              >
                <validation-provider #default="{ errors }" name="settings">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="settings"
                      v-model="deposit_approved.settings"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
          </b-row>
          <b-row
            v-else-if="email_type == 'send-debtor-confirmation'"
            class="col-12"
          >
            <b-col md="4">
              <b-form-group
                id="invoice"
                :label="$t('invoice_id') + ':'"
                label-for="invoice"
              >
                <validation-provider #default="{ errors }" name="invoice">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="invoice"
                      v-model="send_debtor_confirmation.invoice"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-else-if="email_type == 'invoice-sold-debtor'"
            class="col-12"
          >
            <b-col md="4">
              <b-form-group
                id="invoice"
                :label="$t('invoice_id') + ':'"
                label-for="invoice"
              >
                <validation-provider #default="{ errors }" name="invoice">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="invoice"
                      v-model="invoice_sold.invoice"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-else-if="email_type == 'send-debtor-confirmation-2'"
            class="col-12"
          >
            <b-col md="4">
              <b-form-group
                id="invoice"
                :label="$t('invoice_id') + ':'"
                label-for="invoice"
              >
                <validation-provider #default="{ errors }" name="invoice">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="invoice"
                      v-model="send_debtor_confirmation_2.invoice"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else-if="email_type == 'debtor-confirmed'" class="col-12">
            <b-col md="4">
              <b-form-group
                id="invoice"
                :label="$t('invoice_id') + ':'"
                label-for="invoice"
              >
                <validation-provider #default="{ errors }" name="invoice">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="invoice"
                      v-model="debtor_confirmed.invoice"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else-if="email_type == 'send-debtor-warning-first'" class="col-12">
            <b-col md="4">
              <b-form-group
                id="invoice"
                :label="$t('invoice_id') + ':'"
                label-for="invoice"
              >
                <validation-provider #default="{ errors }" name="invoice">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="invoice"
                      v-model="initiate_warning.invoice"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else-if="email_type == 'send-debtor-warning-second'" class="col-12">
            <b-col md="4">
              <b-form-group
                id="invoice"
                :label="$t('invoice_id') + ':'"
                label-for="invoice"
              >
                <validation-provider #default="{ errors }" name="invoice">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="invoice"
                      v-model="warning_reminder.invoice"
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/clipboard.js/2.0.8/clipboard.min.js"></script>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BTr,
  BTh,
  BButton,
  BLink,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

import CustomLoader from "@/components/Common/CustomLoader";
import Table from "@/components/Common/Table2";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ClipboardJS from "clipboard";
export default {
  components: {
    Table,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BTr,
    BTh,
    BButton,
    BLink,
    CustomLoader,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  computed: {
    rows() {
      return this.totalItems;
    },
  },
  data() {
    return {
      language: this.$store.state.app.language,
      send_referee_email: {
        referral: null,
        referred_company: null,
        // referrer_company: null,
      },
      send_referral_email: {
        company: null,
        referrer_company: null,
      },
      withdraw_approved: {
        transacion: null,
        // company: null,
      },
      deposit_approved: {
        transacion: null,
        // company: null,
        // settings: null,
      },
      send_debtor_confirmation: {
        invoice: null,
      },
      send_debtor_confirmation_2: {
        invoice: null,
      },
      debtor_confirmed: {
        invoice: null,
      },
      invoice_sold: {
        invoice: null,
      },
      warning_reminder:{
        invoice: null,
      },
      initiate_warning:{
        invoice: null,
      },
      showCopiedTooltip: [],
      email_type: null,
      email_to: null,
      page: 1,
      totalItems: 0,
      url: "/partners",
      partners: null,
      emailTypes: [
        {
          value: "send-referee-email",
          text: "Send referee email",
        },
        {
          value: "send-referral-email",
          text: "Send referral email",
        },
        {
          value: "withdraw-approved",
          text: "Withdraw approved",
        },
        {
          value: "deposit-approved",
          text: "Deposit approved",
        },
        {
          value: "send-debtor-confirmation",
          text: "Send debtor confirmation",
        },
        {
          value: "send-debtor-confirmation-2",
          text: "Send debtor confirmation last friendly reminder",
        },
        {
          value: "debtor-confirmed",
          text: "Debtor confirmed",
        },
        {
          value: "invoice-sold-debtor",
          text: "Invoice sold (seller&debtor)",
        },
        {
          value: "send-debtor-warning-first",
          text: "Initiate warning",
        },
        {
          value: "send-debtor-warning-second",
          text: "Warning reminder",
        },
        {
          value: "send-code",
          text: "Send code",
        },
        {
          value: "send-forgot-password",
          text: "Send forgot password",
        },
        {
          value: "send-password-reset",
          text: "Send password reset",
        },
        {
          value: "send-welcome",
          text: "Send welcome",
        },
      ],
    };
  },
  created() {},
  methods: {
    sendEmail() {
        this.$refs.emailTesting.validate().then((success) => {
        if (success) {
         let data = {
             email: this.email_to,
             email_type: this.email_type,
             language: this.language
        };
        if(this.email_type == 'send-referee-email'){
            data.referral = this.send_referee_email.referral;
            data.referred_company_id = this.send_referee_email.referred_company;
            // data.referrer_company = this.send_referee_email.referrer_company;
        }
        else if(this.email_type == 'send-referral-email'){
            data.company_id = this.send_referral_email.company;
            data.referrer_company_id = this.send_referral_email.referrer_company;
        }
        else if(this.email_type == 'withdraw-approved'){
            // data.company_id = this.withdraw_approved.company;
            data.transaction_id = this.withdraw_approved.transaction;
        }
        else if(this.email_type == 'deposit-approved'){
            // data.company_id = this.deposit_approved.company;
            data.transaction_id = this.deposit_approved.transaction;
            // data.settings = this.deposit_approved.settings;
        }
        else if(this.email_type == 'send-debtor-confirmation'){
            data.invoice_id = this.send_debtor_confirmation.invoice;
        }
        else if(this.email_type == 'send-debtor-confirmation-2'){
            data.invoice_id = this.send_debtor_confirmation_2.invoice;
        }
        else if(this.email_type == 'debtor-confirmed'){
            data.invoice_id = this.debtor_confirmed.invoice;
        }
        else if(this.email_type == 'invoice-sold-debtor'){
            data.invoice_id = this.invoice_sold.invoice;
        }
        else if(this.email_type == 'send-debtor-warning-first'){
            data.invoice_id = this.initiate_warning.invoice;
        }
        else if(this.email_type == 'send-debtor-warning-second'){
            data.invoice_id = this.warning_reminder.invoice;
        }
        this.$http
                .post(`/sendEmailSimulation`,data)
                .then((res) => {
                    if (res) {
                        if(res.data.status == 200){
                           this.showSuccessMessage();
                        }
                        else{
                          let text = this.$t(`${res.data.message}`);
                          this.$swal({
                              position: "center",
                              icon: "error",
                              title: text,
                              showConfirmButton: false,
                              timer: 1500,
                              customClass: {
                                confirmButton: "btn btn-primary",
                              },
                              buttonsStyling: false,
                            });
                        }
                        
                    }
                });
        }
      });
    },
    showSuccessMessage() {
      let text = this.$t("success");
        this.$swal({
          position: "center",
          icon: "success",
          title: text,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        // this.email_type = null;
        // this.email_to = null;
        // this.send_referee_email.referral = null;
        // this.send_referee_email.referred_company = null;
        // this.send_referral_email.company = null;
        // this.send_referral_email.referrer_company = null;
        // this.withdraw_approved.transaction = null;
        // this.deposit_approved.transaction = null;
        // this.send_debtor_confirmation.invoice = null;
        // this.send_debtor_confirmation_2.invoice = null;
        // this.debtor_confirmed.invoice = null;
        // this.invoice_sold.invoice = null;
        // this.warning_reminder.invoice = null;
        // this.initiate_warning.invoice = null;
      },
  },
};
</script>

<style scoped></style>
