var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-row',{},[_c('b-col',{staticClass:"col-md-12"},[_c('h2',{staticClass:"text-blue font-weight-bold col-md-10 col-lg-10 col-xl-11 mt-1"},[_vm._v(" "+_vm._s(_vm.$t("email_testing"))+" ")])])],1)],1),_c('b-card',[_c('b-row',{staticClass:"col-12"},[_c('h3',{staticClass:"text-blue font-weight-bold col-md-10 col-lg-10 col-xl-11 mb-1"},[_vm._v(" "+_vm._s(_vm.$t("send_email"))+" ")])]),_c('validation-observer',{ref:"emailTesting",attrs:{"name":"emailTesting"}},[_c('b-form',[_c('b-row',{staticClass:"col-12"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"id":"email_type","label":_vm.$t('email_type') + ':',"label-for":"email_type"}},[_c('validation-provider',{attrs:{"name":"email_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"email_type","options":_vm.emailTypes,"state":errors.length > 0 ? false : null,"required":""},model:{value:(_vm.email_type),callback:function ($$v) {_vm.email_type=$$v},expression:"email_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"id":"email_to","label":_vm.$t('email_to') + ':',"label-for":"email_to"}},[_c('validation-provider',{attrs:{"name":"email_to","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":"email_to","required":"","state":errors.length > 0 ? false : null},model:{value:(_vm.email_to),callback:function ($$v) {_vm.email_to=$$v},expression:"email_to"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"id":"language'","label":_vm.$t('language') + ':',"label-for":"language'","rules":"required"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-select',{attrs:{"options":[
                      { text: _vm.$t('german'), value: 'de' },
                      { text: _vm.$t('english'), value: 'en' } ],"id":"language","required":""},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}})],1)],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{staticClass:"mt-1 pt-1",attrs:{"label":""}},[_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.sendEmail()}}},[_vm._v(_vm._s(_vm.$t("send_email")))])],1)],1)],1),(_vm.email_type == 'send-referee-email')?_c('b-row',{staticClass:"col-12"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"id":"referred_company","label":_vm.$t('referred_company_id') + ':',"label-for":"referred_company"}},[_c('validation-provider',{attrs:{"name":"referred_company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"referred_company","state":errors.length > 0 ? false : null},model:{value:(_vm.send_referee_email.referred_company),callback:function ($$v) {_vm.$set(_vm.send_referee_email, "referred_company", $$v)},expression:"send_referee_email.referred_company"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2537822003)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"id":"referral","label":_vm.$t('referral_code') + ':',"label-for":"referral"}},[_c('validation-provider',{attrs:{"name":"referral"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"referral","state":errors.length > 0 ? false : null},model:{value:(_vm.send_referee_email.referral),callback:function ($$v) {_vm.$set(_vm.send_referee_email, "referral", $$v)},expression:"send_referee_email.referral"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2392396851)})],1)],1)],1):(_vm.email_type == 'send-referral-email')?_c('b-row',{staticClass:"col-12"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"id":"referrer_company","label":_vm.$t('referrer_company_id') + ':',"label-for":"referrer_company"}},[_c('validation-provider',{attrs:{"name":"referrer_company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"referrer_company","state":errors.length > 0 ? false : null},model:{value:(_vm.send_referral_email.referrer_company),callback:function ($$v) {_vm.$set(_vm.send_referral_email, "referrer_company", $$v)},expression:"send_referral_email.referrer_company"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"id":"company","label":_vm.$t('company_id') + ':',"label-for":"company"}},[_c('validation-provider',{attrs:{"name":"company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"company","state":errors.length > 0 ? false : null},model:{value:(_vm.send_referral_email.company),callback:function ($$v) {_vm.$set(_vm.send_referral_email, "company", $$v)},expression:"send_referral_email.company"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1):(_vm.email_type == 'withdraw-approved')?_c('b-row',{staticClass:"col-12"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"id":"transaction","label":_vm.$t('transaction_id') + ':',"label-for":"transaction"}},[_c('validation-provider',{attrs:{"name":"transaction"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"transaction","state":errors.length > 0 ? false : null},model:{value:(_vm.withdraw_approved.transaction),callback:function ($$v) {_vm.$set(_vm.withdraw_approved, "transaction", $$v)},expression:"withdraw_approved.transaction"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1):_vm._e(),(_vm.email_type == 'deposit-approved')?_c('b-row',{staticClass:"col-12"},[_c('b-form-group',{attrs:{"id":"transaction","label":_vm.$t('transaction_id') + ':',"label-for":"transaction"}},[_c('validation-provider',{attrs:{"name":"transaction"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"transaction","state":errors.length > 0 ? false : null},model:{value:(_vm.deposit_approved.transaction),callback:function ($$v) {_vm.$set(_vm.deposit_approved, "transaction", $$v)},expression:"deposit_approved.transaction"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,829771457)})],1)],1):(_vm.email_type == 'send-debtor-confirmation')?_c('b-row',{staticClass:"col-12"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"id":"invoice","label":_vm.$t('invoice_id') + ':',"label-for":"invoice"}},[_c('validation-provider',{attrs:{"name":"invoice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"invoice","state":errors.length > 0 ? false : null},model:{value:(_vm.send_debtor_confirmation.invoice),callback:function ($$v) {_vm.$set(_vm.send_debtor_confirmation, "invoice", $$v)},expression:"send_debtor_confirmation.invoice"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1):(_vm.email_type == 'invoice-sold-debtor')?_c('b-row',{staticClass:"col-12"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"id":"invoice","label":_vm.$t('invoice_id') + ':',"label-for":"invoice"}},[_c('validation-provider',{attrs:{"name":"invoice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"invoice","state":errors.length > 0 ? false : null},model:{value:(_vm.invoice_sold.invoice),callback:function ($$v) {_vm.$set(_vm.invoice_sold, "invoice", $$v)},expression:"invoice_sold.invoice"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1):(_vm.email_type == 'send-debtor-confirmation-2')?_c('b-row',{staticClass:"col-12"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"id":"invoice","label":_vm.$t('invoice_id') + ':',"label-for":"invoice"}},[_c('validation-provider',{attrs:{"name":"invoice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"invoice","state":errors.length > 0 ? false : null},model:{value:(_vm.send_debtor_confirmation_2.invoice),callback:function ($$v) {_vm.$set(_vm.send_debtor_confirmation_2, "invoice", $$v)},expression:"send_debtor_confirmation_2.invoice"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1):(_vm.email_type == 'debtor-confirmed')?_c('b-row',{staticClass:"col-12"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"id":"invoice","label":_vm.$t('invoice_id') + ':',"label-for":"invoice"}},[_c('validation-provider',{attrs:{"name":"invoice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"invoice","state":errors.length > 0 ? false : null},model:{value:(_vm.debtor_confirmed.invoice),callback:function ($$v) {_vm.$set(_vm.debtor_confirmed, "invoice", $$v)},expression:"debtor_confirmed.invoice"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1):(_vm.email_type == 'send-debtor-warning-first')?_c('b-row',{staticClass:"col-12"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"id":"invoice","label":_vm.$t('invoice_id') + ':',"label-for":"invoice"}},[_c('validation-provider',{attrs:{"name":"invoice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"invoice","state":errors.length > 0 ? false : null},model:{value:(_vm.initiate_warning.invoice),callback:function ($$v) {_vm.$set(_vm.initiate_warning, "invoice", $$v)},expression:"initiate_warning.invoice"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1):(_vm.email_type == 'send-debtor-warning-second')?_c('b-row',{staticClass:"col-12"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"id":"invoice","label":_vm.$t('invoice_id') + ':',"label-for":"invoice"}},[_c('validation-provider',{attrs:{"name":"invoice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"invoice","state":errors.length > 0 ? false : null},model:{value:(_vm.warning_reminder.invoice),callback:function ($$v) {_vm.$set(_vm.warning_reminder, "invoice", $$v)},expression:"warning_reminder.invoice"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }